import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import * as React from 'react';

import { Site } from '../../components/shells/site';
import { PageTitle } from '../../components/typography/page-title';
import { Seo } from '../../components/utilities/seo';

type Properties = {
    data: any;
};

const Page = ({ data }: Properties): React.ReactElement => {
    return (
        <Site>
            <Seo title="Recepten" />

            <PageTitle>
                {data.contentfulRecipe.title} van {data.contentfulRecipe.source ? data.contentfulRecipe.source.title : '(geen bron)'}
            </PageTitle>

            <GatsbyImage image={getImage(data.contentfulRecipe.photo)} alt={`${data.contentfulRecipe.title} door Fitfoodforty`} />
        </Site>
    );
};

Page.propTypes = {
    data: PropTypes.object.isRequired,
};

export const pageQuery = graphql`
    query ($slug: String!) {
        contentfulRecipe(slug: { eq: $slug }) {
            title
            photo {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 424, height: 212)
            }
            source {
                title
                website
            }
        }
    }
`;

export default Page;
