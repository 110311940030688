import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as React from 'react';

type Properties = {
    children: React.ReactChild | React.ReactChild[];
    className?: string;
};

const PageTitle = ({ children, className }: Properties): React.ReactElement => {
    return <h1 className={classNames('font-bold text-4xl sm:text-5xl md:text-7xl text-glow-md mb-4', className)}>{children}</h1>;
};

PageTitle.propTypes = {
    children: PropTypes.node.isRequired,
};

PageTitle.defaultProps = {};

export { PageTitle };
